export default class ToastErrorType {
    static Info = new ToastErrorType('info');
    static Warning = new ToastErrorType('warning');
    static Error = new ToastErrorType('danger');
    readonly name;

    constructor(name: string) {
        this.name = name;
    }

    toString() {
        return this.name;
    }
}